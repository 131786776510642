@use '../variables';

.creation-form,
.detail-form {
  .header-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 18px;
    font-weight: 600;
  }
  .sub-header-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 16px;
    font-weight: 600;
  }
  .group-action {
    text-align-last: end;
  }
  .value {
    color: variables.$color-black;
    font-weight: 600;
    &.name {
      font-size: 24px;
      .position {
        color: variables.$color-primary;
        font-weight: 500;
      }
    }
  }
  .phone-number {
    display: flex;
    svg {
      vertical-align: top;
    }
  }
  .profile-with-name {
    display: flex;
    align-items: center;
  }
  .right-side {
    .resume-file,
    .cv {
      display: flex;
      align-items: center;
    }
  }
  .invalid {
    color: variables.$color-red;
  }
  .required {
    &::after {
      content: '*';
      color: variables.$color-red;
      vertical-align: middle;
      margin-left: 4px;
    }

    span {
      color: variables.$color-light-red;
    }
  }

  @media screen and (min-width: 1280px) {
    .left-side {
      padding-right: variables.$spacing-lg;
      border-right-width: 1px;
      border-right-color: rgba(0, 0, 0, 0.05);
    }
    .right-side {
      padding-left: variables.$spacing-md;

      .resume-file {
        .download-btn {
          margin-left: auto;
        }
      }
    }
  }
}

form {
  .required {
    span {
      color: variables.$color-light-red;
    }
  }
}
