.scholarship-course {
  .section {
    .group-btn {
      margin-left: auto;
    }
  }

  .tb-subject {
    tr {
      td {
        padding: 2px 5px 0px 5px;
        .mat-form-field {
          margin-top: 20px;
          &.num.mat-form-field-appearance-outline {
            max-width: 100px;
          }
        }
      }
    }
  }
}
