$profile-size: 30;

@mixin cycle($width, $height) {
    width: $width * 1px;
    height: $height * 1px;
    min-width: $width * 1px;
    min-height: $height * 1px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin rounded() {
    border-radius: 50%;
}

.profile-picture-list {
    @include cycle($profile-size, $profile-size);
    @include rounded();
}