@use '../variables' as *;

/* custom mat table */
table.mat-table {
  width: 100%;
  border-radius: $rounded-md;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  thead {
    background-color: $color-t-header;
  }

  tr {
    // &:last-child {
    //   td {
    //     &:first-child {
    //       border-bottom-left-radius: $rounded-md;
    //     }

    //     &:last-child {
    //       border-bottom-right-radius: $rounded-md;
    //     }
    //   }
    // }

    // &:first-child {
    //   th {
    //     &:first-child {
    //       border-top-left-radius: $rounded-md;
    //     }

    //     &:last-child {
    //       border-top-right-radius: $rounded-md;
    //     }
    //   }
    // }

    th {
      // border-top: 1px solid $border-grey;
      font-size: 14px;
      text-transform: capitalize;
      color: $color-primary;
      font-weight: 700;
      border-bottom: 0;

      &:first-child {
        border-bottom-left-radius: $rounded-md;
      }

      &:last-child {
        border-bottom-right-radius: $rounded-md;
      }
    }

    // td {
    //   &:first-of-type {
    //     border-left: 1px solid $border-grey;
    //   }

    //   &:last-of-type {
    //     border-right: 1px solid $border-grey;
    //   }
    // }

    th,
    td {
      // text-align: center;
      padding: 0 $spacing-sm;
      border-bottom-color: rgba(0, 0, 0, 0.05);

      .mat-radio-group {
        display: flex;
        padding: 0;
      }
    }

    &.mat-row:last-child {
      td {
        border-bottom-color: $border-grey;
      }
    }
  }

  //TODO: custom responsive table
  // .mobile-label {
  //   display: none;
  // }

  // @media (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
  //   tr {
  //     td {
  //       //white-space: nowrap;
  //       width: 100%;
  //       justify-content: space-between;
  //       padding: 0;
  //       min-height: 60px;
  //       display: flex;
  //       align-items: center;

  //       &:not(:last-child) {
  //         border-bottom: none;
  //       }

  //       &:last-child {
  //         &>* {
  //           //margin-left: auto;
  //         }
  //       }

  //       span {
  //         text-align: justify;
  //       }
  //     }

  //     .mat-cell {

  //       &:first-of-type,
  //       &:last-of-type {
  //         padding-right: 0;
  //         padding-left: 0;
  //         border-right: 0;
  //         border-left: 0;
  //       }
  //     }

  //     border-bottom: none;

  //     .col-name {
  //       padding-left: 0;
  //     }
  //   }

  //   .mobile-label {
  //     text-align: start;
  //     min-width: 80px;
  //     display: inline-block;
  //     color: $color-grey;
  //     text-transform: capitalize;
  //   }

  //   .mat-header-row {
  //     // display: none;
  //   }

  //   .mat-row {
  //     height: fit-content;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: flex-start;
  //     padding: $spacing-sm $spacing-sm + $spacing-md;

  //     &:last-child {
  //       td {
  //         border: none;
  //       }
  //     }
  //   }
  // }

  //! end of custom responsive table
}

// menu item
.menu-item {
  text-decoration: none;
}
