@use '../variables';

.app-loading {
  z-index: 1050;
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #3333;

  .loader-wrapper {
    width: 100px;
    height: 100px;
    position: absolute;
  }

  .loader {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 10px solid transparent;
    border-top-color: #00aa81;
    border-bottom-color: #0060ef;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
  }

  .loader-inner {
    border-top-color: #d51da7;
    border-bottom-color: #ffb750;
    animation-duration: 2.5s;
  }

  @keyframes rotate {
    0% {
      transform: scale(1) rotate(360deg);
    }

    50% {
      transform: scale(0.8) rotate(-360deg);
    }

    100% {
      transform: scale(1) rotate(360deg);
    }
  }

  body {
    /*   background-color: black */
  }
}

.app-content {
  background-color: white;
  border-radius: variables.$rounded-md;
  max-width: 2000px;
  margin: 0 auto;
  padding: 30px;

  .app-menu-title {
    position: absolute;
    top: 26px;
    left: 42px;
    z-index: 99;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.05rem;
  }

  .app-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .app-return-title {
      flex: 1;
      font-size: 24px;
      font-weight: 600;
      margin: 0;

      .naming {
        color: variables.$silver-chalice;
        font-weight: normal;
      }

      a {
        background-color: #e5e5e5;
        color: #888;
        margin-right: 10px;
      }
    }
  }

  .app-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .title {
      display: grid;
      font-size: 18px;
      font-weight: 600;
      grid-gap: 6px;
      line-height: unset;

      &.two-grid {
        grid-template-columns: auto auto;
      }

      &::after {
        content: '';
        width: 50px;
        height: 5px;
        background: variables.$linear-gradient-primary;
        border-radius: 5px;
      }
    }

    .title1 {
      margin: 0;
      cursor: pointer;
      display: flex;

      span {
        margin-top: 4px;
      }

      .title2 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .create-btn,
    .group-btn {
      margin-left: auto;
    }
  }

  .app-return-title {
    display: flex;
    align-items: center;

    .title {
      font: 500 20px / 32px Open Sans, Khmer, system ui;
      letter-spacing: normal;
      margin: 0;
    }
  }

  .sub-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: variables.$color-primary;
  }

  .code {
    color: gray;
    font-weight: 500;
    font-size: 15px;
  }

  .name {
    font-weight: 500;
    font-size: 30px;
  }

  .chips {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 35px;

    img {
      object-fit: cover;
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }

    &.no-bg {
      padding: 0;
      gap: 8px;
      background: transparent;
      border-radius: none;
    }
  }
}

.profile {
  display: flex;
  flex-direction: row;
  gap: 30px;

  img {
    width: 64px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // .name {
    //   font-size: 18px;
    //   font-weight: bold;
    //   padding-bottom: 10px;
    // }
  }
}

.txt-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

p.field {
  margin: 0 0 5px;
}
