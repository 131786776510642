@use '../variables'as *;
$green: hsl(156deg 55% 31%);

@mixin card {
  background: $color-white;
  border-radius: $rounded-2xl;
}

@mixin btn-credential {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid $color-primary;
}

@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
}

@mixin title {
  font-size: 16px;
  font-weight: bold;
}

.credential-container {
  background: $linear-gradient-primary;
  height: 100vh;
  overflow-x: hidden;

  .col-left {
    float: left;
    width: 50%;
    position: relative;

    height: 100%;

    .instant-image {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: hidden;
    }

    .hat {
      position: absolute;
      background-image: url(/assets/imgs/convocation_capnew.png);
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-size: 200px auto;
    }
  }

  .credential-form {
    width: 100%;
    background-color: $color-primary;
    z-index: 1;
    @include card();
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .logo {
        width: 89px;
        height: 89px;
        border-radius: 50%;
      }
    }

    .welcome {
      display: flex;
      justify-content: center;
      @include title;
      padding: 20px 0px;
      font-size: 30px;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      mat-form-field {
        input {
          color: $color-black;
        }
      }

      .fg-pwd {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;

        a {
          color: $color-black;
          text-decoration: none;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .btn-credential {
      transition: background 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      margin: auto;
      height: 60px;
      width: 100%;
      max-width: 300px;
      font-size: 18px;
    }

    .mat-form-field-infix {
      padding: 1.3em 0em;
    }
  }

  .col-right {
    position: relative;
    width: 50%;
    float: right;
    height: 100vh;
    background-repeat: no-repeat;

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: $color-white;
    }

    .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
      color: #fc7e7e;
    }

    .mat-spinner {
      margin: auto;
      circle {
        stroke: $color-white;
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .credential-container {
    .col-left {
      width: 100%;
      position: absolute;
    }

    .col-right {
      width: 100%;

      .credential-form {
        background-color: #ffffffdb;
      }
    }
  }
}

.lamp {
  clear: both;
  margin-left: auto;
  width: 138px;
  height: 100%;
  background-image: url(/assets/imgs/lamp.png);
  background-repeat: no-repeat;
  background-size: 110px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title {
  @include title();
  text-align: center;
}

.clearfix:after {
  content: '.';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
