@use '../variables';
@use '../abstracts/mixins';

.action-option-dialog {
  .border-bottom {
    border-bottom: 2px solid variables.$color-thin-grey;
  }
  .manage-dialog-content {
    .flex-card-wrap {
      display: flex;
      flex-wrap: wrap;

      .card-item {
        position: relative;
        display: flex;
        text-align: center;
        overflow: hidden;
        min-width: 200px;
        min-height: 124px;
        margin: 10px;
        box-shadow: 0px 3px 6px rgb(89 85 142 / 20%);
        @include mixins.hover-overlay(variables.$color-primary);

        .flex-card {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;

          .card-icon {
            display: block;
            margin-left: auto;
            margin-right: auto;
            background-repeat: no-repeat;
            background-position: center;
            width: 75px;
            height: 75px;
          }
        }
      }

      >a {
        text-decoration: none;
      }

      a {
        flex: 0 1 25%;
      }

      @media (max-width: 1159px) {
        a {
          flex: 0 1 33.33%;
        }
      }

      @media (max-width: 884px) {
        a {
          flex: 0 1 50%;
        }
      }

      @media (max-width: 609px) {
        a {
          flex: 0 1 100%;
        }
      }
    }
  }
}

.transparent .mat-dialog-container{
  background: transparent;
  box-shadow: none;
  position: relative;
  padding: 0px;
  overflow: inherit !important;
}

.backdropBackground {
  background-color: #000000;
  opacity: 0.7 !important;
}


.custom-dialog {
    background-color: white;
    border-radius: 4px;

    .dialog-header {
        display: flex;
        align-items: center;
        place-content: space-between;
        border-bottom: 1px solid #dee2e6;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 12px;
        margin-left: -24px;
        width: calc(100% + 48px);

        .title {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 500;
        }

        .btn-close {
            display: flex;
            align-items: center;
            place-content: center;
            width: 32px;
            height: 32px;
            background-color: rgba(159, 188, 253, 0.1);
            cursor: pointer;
        }
    }

    mat-dialog-content {
        padding: 16px 24px !important;

        &[actions="no"] {
            max-height: calc(65vh + 64px);
        }
    }

    mat-dialog-actions {
        border-top: 1px solid #dee2e6;
        padding: 12px;
    }
}

.transparent {
  .mat-dialog-container {
    .custom-dialog {
      .close-button {
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
      }
    }
  }
}

.reject-dialog {
  .mat-dialog-container {

  }
}
