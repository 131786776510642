@media print {
  @page {
    size: A4 landscape;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: -25px;
  }

  .page-break {
    page-break-before: always;
  }

  .certificate-back {
    margin: 32px;

    h2 {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    .table-container {
      &.table-subject {
        margin-top: 8px;
      }
    }
  }

  .certificate-c1 {
    table.table-certificate-back {
      tbody {
        tr {
          height: 35px !important;
        }
      }
      &.table-grade {
        tbody {
          tr {
            height: 22px !important;
          }
        }
      }
    }
  }
}

#certificate {
  .certificate-wrapper {
    overflow-x: auto;
    overflow-y: hidden;

    .header-title {
      position: absolute;
      top: 21.5%;
      text-align: center;
      width: inherit;
      font-size: 16px;
      line-height: normal;
      color: #414141;
      z-index: 2;

      .title-kh {
        font-family: 'MuolLight';
      }

      .title-en {
        font-family: 'Times New Roman', Times, serif;
      }

      .sub-header {
        font-size: 14px;
        color: #414141;
      }
    }

    .template {
      width: 296.5mm;
      position: relative;
      display: flex;
      flex-direction: column;
      margin: auto;
      background-color: #fff;
      print-color-adjust: exact;

      .template-image {
        width: 100%;
        height: 100%;
        z-index: 3;

        &.overlay-placeholder {
          position: absolute;
          padding: 299px 95px 0;
          z-index: 1;

          .section-col {
            position: relative;

            .input-container {
              display: flex;
              flex-direction: column;
              position: absolute;
              right: 0;
              height: 100%;
              width: 100%;
              row-gap: 11.5px;

              .value {
                outline: none;
                background-color: inherit;
                min-height: 20px;

                span {
                  white-space: nowrap;
                  text-align: center;
                  color: #414141;
                  display: block;

                  &.inline-block {
                    display: inline-block;
                  }
                }
              }

              .flex-base {
                display: flex;
                align-items: baseline;
                margin-left: auto;
              }
            }

            &.en {
              .value {
                font-family: 'Times New Roman';
                font-size: 15px;
                color: rgb(35 32 33);
                font-weight: 600;
              }

              .date-bottom {
                transform: translateY(15px);
              }
            }

            &.kh {
              .value {
                font-family: 'MuolLight';
                font-size: 12px;
                font-weight: normal;
                color: rgb(35 32 33);
              }

              .title-bottom {
                text-align: center;
                color: #373435;
              }

              .date-bottom {
                transform: translateY(5px);
                .value {
                  span {
                    font-family: 'Khmer';
                    font-size: 13px;
                    font-weight: normal;
                    color: #000;
                  }
                }
              }
            }

            .sign-and-stamp-wrapper {
              height: 170px;
              z-index: -1;
            }

            .date-bottom {
              transform: translateY(-20px);
              display: grid;
            }

            .stamp {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: -1;
              margin-right: 120px;
              & > img {
                object-fit: contain;
              }
            }
            .sign-and-stamp {
              // .img {
              // }
              // height: 80px;
              // width: 200px;

              // translate: 0px 95x;
              display: flex;
              z-index: -1;
              // height: 100%;
              align-items: center;

              // max-width: 100%;
              // max-height: 100%;
              // img {
              //   &:last-child {
              //     margin-top: calc(50% - 30px);
              //     translate: (calc((-115 / 3) / 2) + px) 0px;
              //   }
              // }
            }
            .sign {
              translate: 155px -130px;
              display: flex;
              height: 100%;
              align-items: center;

              // img {
              //   &:last-child {
              //     margin-top: calc(50% - 30px);
              //     translate: (calc((-115 / 3) / 2) + px) 0px;
              //   }
              // }
            }
            .name-image {
              translate: 200px -130px;
            }
          }

          .qr-container {
            position: absolute;
            bottom: 4em;
            left: 4.1em;
            scale: 0.8;
            z-index: -2;

            .qr-code-scan {
              height: 90px;

              qrcode {
                canvas {
                  opacity: 0.75;
                }
              }
            }

            .qr-image {
              height: 145px;
            }
          }
        }
      }

      .bg-logo {
        position: absolute;
        width: inherit;
        height: 100%;
        display: grid;
        place-content: center;
        z-index: 0;
        opacity: 0.12;

        img {
          width: 420px;
          height: 420px;
          object-fit: contain;
        }
      }
    }

    &.certificate-c1 {
      .template {
        .template-image {
          &.overlay-placeholder {
            padding: 293px 95px 0 87px;

            .section-col {
              &.kh {
                .input-container {
                  row-gap: 0;
                  .value {
                    height: 23.5px;
                    min-height: initial;

                    span {
                      line-height: 2;
                    }
                  }
                }
              }

              &.en {
                .input-container {
                  row-gap: 2.6px;
                }

                .sign-and-stamp-wrapper {
                  position: relative;

                  .sign-and-stamp {
                    position: absolute;
                    top: -90px;
                  }
                }
              }
            }

            .qr-container {
              left: 78px;
              scale: 1;

              .qr-image {
                margin-bottom: 4px;
                height: auto;
              }
  
              .certificate_number {
                font-size: 11px;
              }
            }
          }
        }
      }

      .certificate-number {
        transform: translate(6px, -220px);
      }

      .profile-image {
        transform: translate(0, -72px);

        .profile-thumbnail {
          width: 120px;
          height: 155px;
          object-fit: contain;
        }
      }
    }

    &.c1-competency {
      .template {
        .template-image {
          &.overlay-placeholder {
            .section-col {
              &.kh {
                row-gap: 0;
                .value {
                  height: 23.5px;
                  min-height: initial;

                  span {
                    line-height: 2;
                  }
                }

                .input-container {
                  row-gap: 7.5px;

                  .date-bottom {
                    transform: translateY(13px);
                  }
                }
              }

              .input-container {
                .date-bottom {
                  transform: translateY(3px);
                }
              }
            }

            .qr-container {
              left: 4.5em;
            }
          }
        }
        .profile-image {
          transform: translate(0, -70px);

          .profile-thumbnail {
            width: 120px;
            height: 155px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .page-break {
    h2,
    h4 {
      font-weight: normal;

      p {
        &:first-of-type {
          font-family: MuolLight, system ui;
        }
      }
    }
  }

  table {
    &.table-certificate-back {
      width: 100%;
      border-radius: 0;
      border: 1px solid #000;

      thead {
        background-color: #d5e2bc;
        print-color-adjust: exact;
      }

      tr {
        &:first-child {
          th {
            &:first-child {
              border-bottom-left-radius: 0;
            }

            &:last-child {
              border-bottom-right-radius: 0;
            }
            border-bottom: 1px solid #000;
          }
        }

        th {
          color: #000;
          text-align: center;

          &:not(:first-of-type) {
            border-left: 1px solid #000;
          }

          font-size: 14px;
          text-transform: capitalize;
          font-weight: 700;
        }

        td {
          color: #000;

          &:not(:first-of-type) {
            border-left: 1px solid #000;
          }

          &.mat-column-status {
            white-space: nowrap;
          }

          .text-no-wrap {
            white-space: nowrap;
          }
        }

        th,
        td {
          padding: 0 8px;
          border-bottom-color: #000;
          line-height: normal;

          > * {
            word-break: break-word;
          }

          .mat-radio-group {
            display: flex;
            padding: 0;
          }
        }

        &.mat-row:last-child {
          td {
            border-bottom-color: transparent;
          }
        }
      }
    }

    &.table-grade {
      tbody {
        tr {
          td {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .align-center {
    text-align: center !important;
  }

  .align-left {
    text-align: left !important;
  }

  .align-right {
    text-align: right !important;
  }

  .flex-1 {
    flex: 1;
  }

  .bg-white {
    background-color: #fff !important;
  }

  .width-full {
    width: 100%;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .place-content-center {
    place-content: center;
  }

  .line-height-normal {
    line-height: normal;
  }

  .certificate-number {
    position: absolute;
    z-index: 2;
    width: inherit;
    display: grid;
    transform: translate(2px, -232px);
    bottom: 0;
    padding-inline: 94px;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-line-clamp: 1;
      text-align: center;
    }
  }

  .profile-image {
    position: absolute;
    z-index: 2;
    width: inherit;
    place-content: center;
    display: grid;
    transform: translate(-11.34px, -94.5px);
    bottom: 0;

    .profile-thumbnail {
      width: 93px;
      height: 206.8px;
      object-fit: cover;
    }
  }

  @for $i from 0 through 800 {
    //margin
    .margin-left-#{$i} {
      margin-left: $i + px;
    }

    .margin-right-#{$i} {
      margin-right: $i + px;
    }

    .margin-top-#{$i} {
      margin-top: $i + px;
    }

    .margin-bottom-#{$i} {
      margin-bottom: $i + px;
    }

    //padding
    .padding-left-#{$i} {
      padding-left: $i + px;
    }

    .padding-right-#{$i} {
      padding-right: $i + px;
    }

    .padding-bottom-#{$i} {
      padding-bottom: $i + px;
    }

    .padding-top-#{$i} {
      padding-top: $i + px;
    }

    //width
    .width-#{$i} {
      width: $i + px;
    }

    //height
    .height-#{$i} {
      height: $i + px !important;
    }

    //transform
    .translate-y-#{$i} {
      transform: translateY($i + px);
    }
    .translate-x-#{$i} {
      transform: translateX($i + px);
    }
    .translate_y-#{$i} {
      transform: translateY(-$i + px);
    }
    .translate_x-#{$i} {
      transform: translateX(-$i + px);
    }
  }
}
