@use '../variables' as var;

.mat-expansion-panel-header {
    &.mat-expanded {
        height: 48px;
    }

    &.mat-expansion-toggle-indicator-before {
        .mat-expansion-indicator {
            display: flex;
        }
    }
}

.mat-expansion-panel-body {
    padding: 12px !important;
    border-radius: 0 0 6px 6px;
    background-color: rgba(0, 0, 0, 0.04)
}

.mat-expansion-panel {
    // border: 1px solid #eaeaea;
    margin: 4px 0 !important;
    border-radius: 6px !important;

    .hightlight {
        margin-left: auto;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var.$color-primary;
    }
}