@use '../variables'as *;
@use 'sass:map';
@use '../constant';
@import "~angular-calendar/css/angular-calendar.css";

//* Calendar's Styles
.holiday-container {
  // background-color: $color-white;
  border-radius: $rounded-md;

  // margin-bottom: $rounded-2xl + 4;
  // padding: $spacing-md + 4;
  &__btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    button {
      border-color: $color-primary;
      color: $color-primary;

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        margin-right: 8px;
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .holiday-container__cal-title {
    font-size: 25px;
    font-weight: bold;
    color: $color-primary;
  }

  //My Custom
  .calendar-header {
    //width: 100%;

    // display: flex;
    // align-items: center;
    // margin-bottom: $spacing-lg - 7;
    .calendar-button {
      // color: $color-primary;

      // button {
      //   border: 1px solid $color-primary;
      //   ;
      //   padding: $spacing-xs - 2 $spacing-md + 4;
      // }

      .date-today {
        position: relative;
        border-radius: $rounded-sm;
        background: $color-white;
        z-index: 1;
        min-width: 145px;

        .hidden {
          position: absolute;
          top: 0;
          visibility: hidden;
        }
      }

      .date-back {
        // border-right: none;
        transform: translateX(5px);

        mat-icon {
          line-height: 20px;
        }
      }

      .date-forward {
        // border-left: none;
        transform: translateX(-5px);

        mat-icon {
          line-height: 20px;
        }
      }
    }

    .date-display {
      width: 73%;
      text-align: center;

      span {
        font-size: 25px;
        color: $color-primary;
        ;
        font-weight: bold;
      }
    }
  }

  //!Align Text Space Between
  .group-btn {
    margin-left: auto;
  }

  // .flex-media {
  //   align-items: center;
  //   margin-top: $spacing-md;
  // }

  .show-calendar {
    @media screen and (min-width: 1280px) {
      padding-right: $spacing-md;
      border-right-width: 1px;
      border-right-color: $color-thin-grey;
    }

    .cal-month-view {
      .cal-day-cell.cal-weekend {
        .cal-day-number {
          color: $color-black;
        }
      }

      .cal-day-number {
        opacity: 1;
      }

      .cal-day-cell.cal-out-month {
        .cal-day-number {
          opacity: 0.3;
        }
      }

      .cal-day-cell:not(:last-child) {
        border-right-color: $color-white;
      }

      .cal-days {
        border-color: $color-white;
      }

      .cal-open-day-events {
        .cal-event-actions {
          .button {
            border-radius: 6px;
          }
        }
      }

      .cal-cell-row {
        border-bottom-color: $color-white;
        background-color: $color-primary;
      }

      mwl-calendar-month-cell {
        background-color: #F9F9F9;

        &:last-child {
          .cal-day-number {
            color: $color-red !important;
          }
        }
      }

      .cal-cell-row {
        &:hover {
          background-color: $color-primary;
        }
      }

      mwl-calendar-month-view-header {
        .cal-cell-row {
          border-top-left-radius: $rounded-sm;
          border-top-right-radius: $rounded-sm;

          .cal-cell {
            &:hover {
              background-color: $color-primary;
              border-top-left-radius: $rounded-sm;
              border-top-right-radius: $rounded-sm;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 650px) {
    .flex-media {
      flex-direction: column !important;
    }

    .calendar-header {
      max-width: 100%;
      justify-content: center;
    }

    .calendar-title {
      max-width: 100% !important;
    }
  }
}

.holiday-event {
  @media screen and (min-width: 1280px) {
    margin-top: $spacing-md - 1;
    padding-left: $spacing-md;
  }

  .holiday-event__header {
    font-weight: bold;
    margin-bottom: $spacing-md;
    font-size: 25px;
    color: $color-primary;
  }

  &__containter {
    font-size: 15px;
  }

  &__item-row {
    display: flex;
    border: 1px solid $color-thin-grey;
    max-width: 100%;
    min-height: 70px;
    border-radius: $rounded-md;

    &:not(:last-child) {
      margin-bottom: $spacing-md;
    }
  }

  &__item-content{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8E9ED;
    padding: $spacing-md $spacing-sm;
    margin: 0 $spacing-md 0 0;
    // box-shadow: 0 0 5px $color-grey;
    border-radius: 8px 0 0 8px;
  }

  &__cal-event {
    width: auto;
    text-align: center;
  }

  &__title {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      &:first-child {
        margin-right: $spacing-md;
      }

      &:last-child {
        font-weight: bold;
      }
    }:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #9A9A9A;
    font-size: 14px;
  }

  &__date {
    text-align: center !important;
    margin-bottom: 13px;
    width: 56px;

  }
  

  &__time {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);;
    border-top: 1px solid #d2d2d3;
    padding-top: 10px;
    width: 56px;
  }

  &__status {
    display: flex;
  }

  &__student{
    display: flex;
    align-items: center;
    padding: 4.5px 8px;
    font-weight: 400;
    font-size: 12px;
    width: 63px;
    line-height: 16px;
    color: #FFFFFF;
    background: #25BBFF;
    border-radius: 23px;
    margin-right: 12px;
    margin-top: 12px;
  }

  &__school {
    display: flex;
    align-items: center;
    padding: 4.5px 8px;
    font-weight: 400;
    font-size: 12px;
    width: 63px;
    line-height: 16px;
    color: #FFFFFF;
    background: #85E13A;
    border-radius: 23px;
    margin-right: 12px;
    margin-top: 12px;
  }

  &__image{
    margin-left: auto;
  }

  .date{
    font-size: 22px;
    color: #F2021E;
    font-weight: 600;
    text-align: center;
  }

  .content{
    padding: $spacing-sm $spacing-sm $spacing-sm 0;

    .border-bottom{
      border-top: 1px solid rgb(219, 219, 219);
      width: 100%;
    }
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    .img {
      max-width: 150px;
      max-height: 150px;
      object-fit: cover;
      border-radius: 8px;

      img{
        width: 100px;
        height: 85px;
        border-radius: 10px;
      }
    }
  }

  .border-right{
    margin-top: 5px;
    margin-right: 5px;
    border-right: 1px solid rgb(233, 233, 233);
  }
}

.cal-month-view {
  .cal-header {
    .cal-cell {
      color: $color-white;

      font-weight: 500;
      padding: 0.75rem;
      font-size: 16px;
    }
  }

  .cal-day-cell.cal-today {
    border: 1px solid $color-grey !important;

    .cal-day-number {
      font-size: 22px;
    }
  }

  .cal-day-number {
    font-size: 17px;
  }

  .cal-day-badge {
    width: 25px;
    height: 25px;
    border-radius: $rounded-full;
    background-color: $color-primary;
    padding-top: $spacing-xs;
  }

  .cal-event {
    width: auto;
    min-width: 12px;
    height: 12px;
    border-radius: $rounded-full;
    box-shadow: 0 0 5px $color-grey;
  }

  .cal-event-title {
    font-size: 17px;
    color: $color-black;
  }

  .cal-open-day-events {
    background-color: #4f957321;
    box-shadow: 0 1px 5px -3px $color-primary inset;

    div:not(:last-child) {
      margin-bottom: 12px;
    }

    .cal-event-actions {
      .button {
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-left: 10px;
        width: 35px;
        height: 34px;
        text-align: center;
        display: inline-block;

        img {
          width: 22px;
          transform: translateY(5px);
        }
      }

      .add-action {
        transform: translateX(-28px);
      }
    }
  }

}
