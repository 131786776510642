.score-creation {
  tr {
    &.mat-row {
      height: 60px;

      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-subscript-wrapper {
          display: none;
        }
      }
    }
  }
}
