@use '../mixin/flex' as *;
@use '../variables' as var;

// filter
.report-filter {
  form {
    display: grid;
    gap: 16px;
    align-items: center;

    .print-btn {
      margin-left: auto;
    }
  }

  .mat-form-field {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      min-width: 18px;
      background-color: #fff;
    }

    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: 'sm'))) {
    form {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: 'md'))) {
    form {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .generate-report-btn {
        width: fit-content;
      }
    }
  }
}

.report {
  .text-bold {
    font-weight: 600;
  }

  .text-blur {
    color: var.$aluminium;
  }

  .flex {
    &.wrap {
      flex-wrap: wrap;
    }
  }

  .place-content-space-between {
    justify-content: space-between;
  }

  .app-title,
  .app-header {
    margin-bottom: 0;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;

    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      background-color: #fff;
    }

    .mat-form-field-label-wrapper {
      mat-label {
        margin-left: 10px;
      }
    }
  }

  .display-none {
    display: none;
  }

  .table-container {
    border: 1px solid var.$athens-gray-color;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    table.mat-table {
      .mat-header-row {
        height: 65px;
      }

      th {
        &.th-center {
          text-align: center;
        }
      }

      td {
        &.td-center {
          text-align: center;
        }

        &.sub-header {
          background-color: rgba(var.$color-primary, 0.1);
        }
      }

      thead {
        tr {
          background-color: var.$color-primary;
          color: #fff;

          &:first-child {
            th {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          &:last-child {
            height: 50px;

            th {
              &:first-child {
                border-left: 1px solid var.$ship-cove-color;
              }
            }
          }

          th {
            border: 0;
            color: #fff;
            border-bottom: 1px solid var.$ship-cove-color;

            &:first-child {
              text-align: center;
              border-bottom-left-radius: 0;
              padding-inline: 16px;
            }

            &:last-child {
              padding-right: 8px;
              border-bottom-right-radius: 0;
            }

            &:not(:first-child) {
              border-left: 1px solid var.$ship-cove-color;
            }
          }
        }
      }

      tbody {
        tr {
          &:last-child {
            &:not(.mat-no-data-row) {
              background-color: rgba(var.$sea-buckthorn, 0.1);
            }

            td {
              font-weight: bold;
              border-left: none;
              border-bottom-color: transparent;

              &:first-child {
                text-align: center;
              }
            }
          }

          &:not(.mat-no-data-row) {
            td {
              border-bottom-color: var.$athens-gray-color;

              &:not(:first-child) {
                border-left: 1px solid var.$athens-gray-color;
              }
            }
          }

          td {
            &:first-child {
              text-align: center;
              padding-inline: 16px;
            }

            &:last-child {
              padding-right: 8px;
            }
          }
        }
      }
    }
  }

  .simple-table {
    .table-container {
      tbody {
        tr {
          &:last-child {
            &:not(.mat-no-data-row) {
              background-color: #fff !important;
            }

            td {
              font-weight: normal !important;
            }
          }
        }
      }
    }
  }

  app-filter {
    .filter-card {
      margin-bottom: 0;
    }
  }

  .report-empty {
    height: 50dvh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
