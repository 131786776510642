@use '../variables';

.group-btn {
  button:not(:last-child) {
    margin-right: variables.$spacing-md;
  }
}

/* button small */
.btn-sm {
  min-width: 32px !important;
  padding: 0 variables.$spacing-xs !important;
}

/* button large */
.btn-lg {
  width: 300px;
  border-radius: variables.$rounded-xl !important;
  padding: 10px variables.$spacing-md !important;
  font-size: 20px;
}