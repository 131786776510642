/* color */
$color-primary: #2193b0;
$color-secondary: #63677d;
$color-grey: #b4b4b4;
$color-white: #ffffff;
$color-red: #ff0000;
$color-black: #000000;
$color-thin-grey: #E8E9ED;
$color-success: #4f9573;
$color-warn: #FFC42A;
$color-light-red: #ff6464;
$color-short-course: #39CADA;
$color-long-course: #194F82;
$color-required: #fc7e7e;
$japanese-laurel: #006604;

$silver-chalice: #a0a0a0;
$fade-green: #4f957329;
$alabaster: #f9f9f9;
$scarpa-flow: #53535f;
$black-squeeze-color: #edf2f9;
$athens-gray-color: #f4f4f7;
$mercury-color: #e6e6e6;
$aluminium: #a6a7ae;
$japanese-laurel: #006604;
$aqua-squeeze: #f1f9f7;
$color-t-header: $aqua-squeeze;
$black-squeeze-light: #f8fafd;
$ship-cove-color: #54a8c4;

//attendance status
$salem: #00bf08;
$bittersweet: #fa2020;
$deluge: #a200ee;
$sea-buckthorn: #f89f37;

/* border color */
$border-grey: #E8EAEF;
$border-red: red;

/* background color */
$background-grey: #f5f5f5;
$background-light-grey: rgba(0, 0, 0, 0.02);

/* linear gradient */
$linear-gradient-primary: linear-gradient(269.73deg, #6DD5ED 0.03%, #2193B0 99.95%);

/* rounded (like: border-radius) */
$rounded-sm: 6px;
$rounded-md: 10px;
$rounded-lg: 15px;
$rounded-xl: 20px;
$rounded-2xl: 30px;
$rounded-full: 50%;

/* spacing (like: margin, padding) */
$spacing-xs: 6px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

/* scss-docs-start grid-breakpoints */
$grid-breakpoints: (
  /* Mobile */
  'xs': 0,
  /* Tablet */
  'sm': 600px,
  /* Small Laptop */
  'md': 960px,
  /* Large Laptop */
  'lg': 1280px,
  /* Desktop */
  'xl': 1920px) !default;

/* scss edges */
$edges: (
  't': top,
  'r': right,
  'b': bottom,
  'l': left) !default;

$edge-axis: x,
  y !default;

/* scss borders */
$border-styles: solid,
  dashed,
  dotted,
  double,
  none;

/*scss layout */
$layout-gap: 12px;

/* if not pass any value mean value = 1 */
@mixin line-clamp($value: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: $value;
}
