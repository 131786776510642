@use './variables' as *;

$sizes: (
    "sm": $rounded-sm,
    "md": $rounded-md,
    "lg": $rounded-lg,
    "xl": $rounded-xl,
    "2xl": $rounded-2xl,
    "full": $rounded-full
);

$text-colors: (
    "primary": $color-primary,
    "white": $color-white,
    "grey": $color-grey,
    "black": $color-black
);

$background-colors: (
    "primary": $color-primary,
    "white": $color-white,
    "grey": $background-light-grey,
);

$linear-gradient-colors: (
    "gradient-primary": $linear-gradient-primary,
);

@mixin profile-picture() {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: $rounded-full;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin profile-picture-list() {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: $rounded-full;
    margin-top: $spacing-sm;
    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
}

/* rounded */
@each $name,
$size in $sizes {
    .rounded-#{$name} {
        border-radius: $size  !important;
    }
}

/* text color */
@each $name,
$color in $text-colors {
    .text-#{$name} {
        color: $color;
    }
}

/* background color */
@each $name,
$color in $background-colors {
    .bg-#{$name} {
        background-color: $color;
    }
}

@each $name,
$color in $linear-gradient-colors {
    .bg-#{$name} {
        background: $color;
    }
}

/* profile picture */
.profile-picture {
    @include profile-picture();
    border: 1px solid $background-grey
}

.profile-picture-list {
    @include profile-picture-list();
}

/* Disabled checkbox */
.custom-box.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: $color-primary;
}

// text overflow
.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    &__1 {
        -webkit-line-clamp: 1;
    }

    &__2 {
        -webkit-line-clamp: 2;
    }
}

.flex {
    display: flex;

    &.items-center {
        align-items: center;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.flex-wrap {
      flex-wrap: wrap;
    }
}
