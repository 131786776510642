@use '../variables' as var;

.empty {
    &.full-height {
        height: 100vh;
    }

    text-align: center;

    &__title {
        margin-top: 8px !important;
    }

    &__text {
        color: var.$color-secondary;
        font-size: 16px;
    }
}