@use './base/base';

/* themes angular material */
@use './themes/custom-material';
@use './themes/mat-buttons';
@use './themes/mat-card';
@use './themes/mat-table';
@use './themes/mat-form-field';
@use './themes/mat-radio-button';
@use './themes/mat-tab';
@use './themes/mat-menu';
@use './themes/mat-dialog';

@use './variables';
@use './constant';

/* mixin */
@use './mixin/grid';
@use './mixin/margin';
@use './mixin/padding';
@use './mixin/border';
@use './mixin/cycle';
@use './mixin/flex';

/* components */
@use './components/app';
@use './components/buttons';
@use './components/table';
@use './components/filter';
@use './components/snackbar';
@use './components/creation-form';
@use './components/dialog';
@use './components/timetable';
@use './components/datepicker-arrow';
@use './components/chart';
@use './components/print';
@use './components/report';
@use './components/skeleton';
@use './components/searchbar_in_select';

/* layout */
@use './layouts/menu';
@use './layouts/pagination';

/* page */
@use './pages/about-of-study';
@use './pages/login';
@use './pages/staff';
@use './pages/not-found';
@use './pages/announcement';
@use './pages/school';
@use './pages/calendar';
@use './pages/home';
@use './pages/file-document';
@use './pages/certificate';
@use './pages/student';
@use './pages/transcript';
@use './pages/academic_program';
@use './pages/score';

// materials
@use './materials/mat-list';
@use './materials/mat-expansion';

/* vendor */
@use './vendors/quill-js.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/* video.js */
@import "~video.js/dist/video-js.css";
