@use '../variables/' as *;

.student-info {
  .flex-wrap {
    flex-wrap: wrap;
  }

  .image-wrapper {
    .image-box-wrapper {
      position: relative;
    }

    .image-box {
      width: 250px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      cursor: pointer;

      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        box-shadow: 0 0 5px $mercury-color;
      }

      .button-remove {
        position: absolute;
        right: -8px;
        top: -4px;
        width: 24px;
        height: 24px;
      }

      &__border-dashed {
        border: 1px dashed $color-primary;

        .grid {
          place-items: center;
        }
      }

      &.disabled-upload {
        pointer-events: none;
        background-color: $background-grey;
        filter: grayscale(100%);
      }
    }
  }
}

.student-finish-wrapper {
  .cursor-pointer {
    cursor: pointer;
  }

  .margin-0 {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 8px;
  }

  .text-blur {
    color: $aluminium;
  }

  .student-finish {
    &__timeline {
      .line-warpper {
        position: relative;

        .circle {
          display: block;
          margin-top: 18px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          border: 4px solid;
          background-color: #fff;

          &.quit,
          &.rejected {
            border-color: $color-light-red;
          }

          &.approved,
          &.active {
            border-color: $salem;
          }

          &.requesting,
          &.pending {
            border-color: $sea-buckthorn;
          }
        }

        .horizontal-line {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;

          .line {
            border-left: 2px dashed $mercury-color;
            height: inherit;
            display: block;
          }
        }
      }

      .create_by {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__status {
          .mobile-on {
            display: none;
          }

          p.updated-text {
            font-size: 16px;
          }
        }

        &__quit-reason {
          width: 100%;

          &__wrapper {
            background-color: $black-squeeze-light;
            margin-left: 40px;
          }
        }
      }

      @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        .mobile-off {
          display: none;
        }

        .create_by {
          &__status {
            .mobile-on {
              display: block;
              width: 100%;
            }
          }

          &__quit-reason {

            &__wrapper {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
