@use '../variables';

//*Faculty Detail's Styles
.faculty-detail,
.department-detail,
.building-detail {
  .sub-title {
    color: variables.$color-primary;
    font-size: 16px;
    font-weight: 500;
  }

  .title {
    align-items: center;
  }

  .flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
  }

  ._content {
    width: 100%;
    max-width: 36em;
  }

  .break-line {
    width: 100%;
    height: 1px;
    background-color: variables.$color-thin-grey;
  }

  .t-header-action {
    align-items: center;

    .create-button {
      margin-left: auto;
    }
  }

  .group-btn {
    margin-left: auto;

    button {
      &:not(:last-child) {
        margin-right: variables.$spacing-sm;
      }
    }
  }
}

table {
  td.wrap-text {
    word-wrap: break-word;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
