@use '../variables' as var;

.home {
    max-width: 2000px;
    margin: 0 auto;

    // Header
    .flex-header {
        position: absolute;
        top: 13px;
        left: 42px;
        z-index: 2;
        display: flex;
        align-items: center;

        .title {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: -0.05rem;
            width: 130px;

            // &::after {
            //     content: "";
            //     width: 50px;
            //     height: 5px;
            //     background: var.$linear-gradient-primary;
            //     border-radius: 5px;
            // }
        }

        .academic-filter {
            margin-left: 16px;

            &.mat-select {
                height: 44px;
                border: 1px solid #e7e7e7;

                &.academic-filter {
                    line-height: 32px;
                    min-width: 200px;
                    font-size: 15px;
                    font-weight: 400;
                    border-radius: 10px;
                    background-color: #fff;
                    padding: 0.25em 0.5em;
                }

                mat-select-trigger {
                    display: flex;
                    align-items: center;

                    .mat-icon {
                        margin-right: 6px;
                        color: var.$color-secondary;
                    }
                }
            }

        }
    }

    // Statistic summary
    .stat-detail {
        height: 100%;
        display: flex;
        align-items: center;

        div {
            margin-left: 1em;
        }

        h1 {
            font-weight: bold;
        }

        h1,
        p {
            color: #24292f;
            margin: 0;
        }
    }

    // Statistic chart
    .chart-container {
        .card-header {
            margin: 0 16px;
            font-weight: 600;
        }
    }
}