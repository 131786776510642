@use '../variables';

.mat-dialog-container {
  border-radius: variables.$rounded-lg  !important;
  box-shadow: none;
  position: relative;

  .app-content {
    padding: 0 20px;

    .app-title{
      margin-bottom: 0px;
    }
  }

  .mat-dialog-actions {
    padding: 0 0 20px 0!important;
  }
}

.transparent .mat-dialog-container {
  background: transparent;
  box-shadow: none;
}

.backdropBackground {
  background-color: #000000;
  opacity: 0.7 !important;
}