@use '../variables' as *;

.snackbar-custom {
  .snackbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .snackbar-message {
    display: flex;
    align-items: center;

    span {
      color: #fff;
      font-weight: 500;
    }

    mat-icon {
      margin-right: 5px;
    }
  }

  #snackbar-duration {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mat-progress-bar {
    .mat-progress-bar-fill {
      &::after {
        background-color: $color-white;
      }
    }
  }

  &.snackbar-success {
    background-color: #4caf50;

    .mat-progress-bar-buffer {
      background-color: #4caf50;
    }
  }

  &.snackbar-error {
    background-color: #f44336;

    .mat-progress-bar-buffer {
      background-color: #f44336;
    }
  }
}

.cdk-global-overlay-wrapper + .snackbar-custom {
  pointer-events: visible;
}
