@use '../variables' as *;
@use '../mixin/flex' as *;

.create-staff,
.staff-detail {
  .right-side {
    .selected-sub {
      position: relative;
      text-align: center;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin-top: auto;
        margin-bottom: auto;
        content: '';
        width: 8px;
        height: 70%;
        background-color: #FF8888;
        border-radius: $rounded-md;
      }
    }
  }
}

.staff-list-attendance {
  .table-container {
    overflow-x: auto;

    tr {
      .header-center {
        text-align: center;
      }

      td {
        .wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .session-wrap {
            width: fit-content;
            position: relative;

            .button-remove {
              position: absolute;
              right: -10px;
              top: -5px;
              z-index: 1;


              mat-icon {
                height: 20px;
                width: 20px;
                line-height: 1;
              }
            }

            button.session {
              border: 1px solid $color-thin-grey;
              margin: 15px 8px;
              padding: 5px 15px;
              border-radius: 10px;
              width: 15.6em;

              .text {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .time {
                  line-height: 25px;
                  text-align: left;
                  max-width: 85%;

                  .name {
                    display: block;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: auto;
                  }

                  .hour {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    mat-icon {
                      height: 18px;
                      width: 18px;
                      min-width: 18px;
                      line-height: 1;
                    }

                    span {
                      font-weight: 500;
                      font-size: 12px;
                      display: block;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: auto;
                    }
                  }
                }
              }
            }

            .present {
              color: #fff;
              background-color: $salem;
            }

            .absent {
              color: #fff;
              background-color: $bittersweet;
            }

            .permit {
              color: #fff;
              background-color: $deluge;
            }
          }
        }
      }

      .name {
        min-width: 12em;
      }

      @media screen and (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        &:not(:last-child) {
          border-bottom: 1px solid $color-thin-grey;
        }

        th {
          &:not(:last-child) {
            border-right: 1px solid $color-thin-grey;
          }
        }

        td {
          &:not(:last-child) {
            border-right: 1px solid $color-thin-grey;
          }
        }

        th.session {
          text-align: center;
        }

        td.position {
          width: 65px;
        }
      }
    }
  }

}

.attendance-type {
  .mat-menu-content:not(:empty) {
    padding: 22px;

    mat-button-toggle-group {
      border: none;
      border-radius: 0;
      flex-direction: column;

      mat-button-toggle {
        border: 1px solid $border-grey;
        border-radius: 8px;

        button {
          width: 185px;
          height: 35px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .mat-button-toggle-label-content {
            line-height: 0;

            .label {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              span {
                width: 8px;
                height: 8px;
                display: block;
                border-radius: 100%;
                margin-right: 12px;
              }
            }
          }
        }
      }

      .none {
        .circle {
          background-color: $silver-chalice;
        }
      }

      .present {
        .circle {
          background-color: $salem;
        }
      }

      .absent {
        .circle {
          background-color: $bittersweet;
        }
      }

      .permit {
        .circle {
          background-color: $deluge;
        }
      }

      .mat-button-toggle-checked.present {
        background-color: $salem;
      }

      .mat-button-toggle-checked.absent {
        background-color: $bittersweet;
      }

      .mat-button-toggle-checked.permit {
        background-color: $deluge;
      }

      .mat-button-toggle-checked.present,
      .mat-button-toggle-checked.absent,
      .mat-button-toggle-checked.permit {
        .label {
          color: #fff;

          .circle {
            background-color: #fff;
            border: none;
          }
        }
      }

      .mat-button-toggle-checked.none {
        .label {
          color: #000;

          .circle {
            background-color: #fff;
            border: none;
          }
        }
      }
    }
  }
}

.staff-list-attendance-dialog,
.staff-attendance {
  .name {
    color: $color-primary;
  }

  td {

    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        margin-right: 12px;
      }
    }

    .present {
      span {
        background-color: $salem;
      }
    }

    .absent {
      span {
        background-color: $bittersweet;
      }
    }

    .permit {
      span {
        background-color: $deluge;
      }
    }
  }
}

.time-range {
  mat-option.mat-selected {
    background-color: $fade-green !IMPORTANT;
  }
}

.staff_schedule {
  margin-left: auto;

  .group-btn {
    margin: 0;

    .date-today {
      position: relative;
      border-radius: $rounded-sm;
      background: $color-white;
      z-index: 1;
      min-width: 145px;

      .hidden {
        position: absolute;
        top: 0;
        visibility: hidden;
      }
    }

    .date-back {
      // border-right: none;
      transform: translateX(5px);
    }

    .date-forward {
      // border-left: none;
      transform: translateX(-5px);
    }
  }

  .schedule {
    overflow-x: auto;
    @include flexbox;
    @include flex-direction(row);
    gap: 16px;

    .table {
      @include flexbox;
      @include flex-direction(row);
      min-width: 500px;

      .column {
        @include flexbox;
        @include flex-direction(column);

        &:first-child {
          .header {
            border-top-left-radius: 20px;
          }

          .col-subject {
            border-bottom-left-radius: 20px;
          }
        }

        &:last-child {
          .header {
            border-top-right-radius: 20px;
          }

          .col-subject {
            border-bottom-right-radius: 20px;
          }
        }

        .header {
          @include flexbox;
          @include flex-direction(row);
          font-size: 14px;
          color: #fff;
          max-width: 400px;
          min-width: 212px;
          align-items: center;
          justify-content: center;
          background: $color-primary;
          // border-radius: 20px 20px 0px 0px;
          width: fit-content;
          padding: 20px 6px;

          .is-current-date {
            background: #FFFFFF;
            // border: 1px solid #E8E9ED;
            border-radius: 10px;
            color: #000;
            padding: 0px 8px;
            // width: fit-content;
          }

          .is-selected-date {
            &::before {
              content: "•";
              color: white;
              display: inline-block;
              width: 0.6em;
              margin-left: -1em;
              font-weight: bolder;
            }
          }
        }

        .col-subject {
          @include flexbox;
          @include flex-direction(column);

          min-height: 500px;
          border: 0.8px solid #E8EAEF;

          &:nth-child(1) {
            border-end-start-radius: 21px;
            color: $color-red;
          }


          .subject {
            @include flexbox;
            width: 205px;
            height: 148px;
            // border-left: 3px solid rgba($color: #ee07a1, $alpha: 0.9);
            border-top-left-radius: 5px;
            // background: rgba(57, 202, 218, 0.1);
            border-radius: 5px;
            margin: 2px;

            hr.vertical {
              border: 0;
              margin: 0;
              // border-left: 5px solid blue;
              height: 148px;
              float: left;
              border-radius: 5px;
            }

            .container {
              @include flexbox;
              @include flex-direction(column);
              padding: 12px;

              .content {
                &:first-child {
                  font-weight: 600;
                  font-size: 14px;
                }

                &:last-child {
                  font-weight: 600;
                  opacity: 0.85;
                  margin-top: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
